import React, { useEffect, useState } from "react"
import styled, {css} from "styled-components"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import {RandomClamped} from "./HeroBackdrop"

const Content = styled('div')`
  width: 50%;
  padding-left: clamp(36px, 15vw, 250px);
  display: flex;
  flex-direction: column;
  position:relative;
  padding-right: 36px;
  line-height: 1.6;
  & p {
    max-width: 480px;
    flex: 1;
    margin-bottom: 48px;
  }
  & h2 {
    text-align: left;
    color: var(--sp-orange); 
  }
  & ol {
    padding: 48px;
    border-radius: 6px 0 0 6px;
    background: hsla(22, 100%, 50%, 0.1);
    bottom: 4px;
    width: calc(100% + 36px);
    //list-style-type: none;
  }
`

const Screenshot = styled(motion.div)`
  width: 50%;
  height: inherit;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  & div{
    width: 100%;
    height: 100%;
    position:relative;
  }
  & img {
    height: 100%;
    position: relative;
    top: 3px;
    //width: clamp(480px, calc(50vw - 100px), 1060px);
    width: 100%;
    border-radius: 3px;
    object-fit: cover;
    object-position: left center;
    box-shadow: 0 12px 24px 0 rgba(250, 169, 122, 0.45);
    
  }
  & svg {
    height: 100%;
    width: 1px;
    position:absolute;
    top: 0;
    overflow: visible;
  }
`

const Container = styled('div')`
  scroll-margin-top: 100px;
  display: flex;
  flex-direction: ${props => props.flipped ? 'row-reverse' : 'row'};
  margin-bottom: 200px;
  max-width: 100%;
  pointer-events: none;
  ${props => props.flipped ? css`
    & > div:nth-of-type(1) { /*Content*/
      padding-left: 72px;
      padding-right: 72px;
      & ol {
        transform: translateX(-72px);
        padding-left: 72px;
      }
    }
    & > div:nth-of-type(2){ /*Screenshot*/
    justify-content: flex-start;
      svg {
        position: absolute;
        right: 0;
        transform: scaleX(-1);
      }
      img{
        float: right;
        object-position: right center;
      }
    }
  ` : null};
   
   @media(max-width: 768px){
    flex-direction: column;
    margin-bottom: 50px;
    & > div {
      width: 100%;
    }
  }
`


const FeatureHighlight = ({id, imgSrc, children, ornamentLocation, flipped}) => {

  const [ref, inView] = useInView({
    rootMargin: '-200px 0px',
    // threshold: 0.8
  })
  const _NUM_SHAPES = 8
  const _SIZE_SHAPE = 40
  const [TransformStrings, setTransformStrings] = useState([]);
  useEffect(()=>{
    const strings = [];
    for (let i = 0; i < _NUM_SHAPES; i++) {
      strings.push(`
        scale(${Math.random()})
        translate(-${_SIZE_SHAPE / 2} -${_SIZE_SHAPE / 2})
        translate(0 ${(_SIZE_SHAPE/2) + Math.random() * 100})     
        rotate(45 ${_SIZE_SHAPE / 2} ${_SIZE_SHAPE / 2})
      `)
    }
   setTransformStrings(strings)
  }, [setTransformStrings])

  return (
    <Container flipped={flipped} id={id}>
      <Content>{children}</Content>
      <Screenshot >
        <svg viewBox={'0 0 100 100'} preserveAspectRatio="xMinYMin slice">
          <g transform={
            ornamentLocation !== 'bottom' && ornamentLocation !==  'top' ?
              null
              :
              `rotate(${ornamentLocation === 'top' ? -90 : 90 } 0 ${ornamentLocation === 'top' ? 0 : 100 })`
          }>
          {[...Array(_NUM_SHAPES)].map((nil, i) => (
            <motion.rect
              width={_SIZE_SHAPE}
              height={_SIZE_SHAPE}
              fill={`hsl(22, 100%, ${RandomClamped(Math.random()*100, 50, 100)}%)`}
              key={'backdropshape_' + i}
              fillOpacity={RandomClamped(Math.random(), 0, 0.33)}
              transform={TransformStrings[i]}
            />
          ))}
          </g>
        </svg>
        <div>
          <motion.img
            ref={ref}
            transition={{ type: "spring", stiffness: 50 }}
            animate={{ x: inView ? 0 : (flipped ? -240 : 240), opacity: inView ? 1 : 0  }}
            src={imgSrc}
            alt={"Sheetplanner screenshot"}
            whileHover={{x:'120'}}
          />
        </div>
      </Screenshot>
    </Container>
  )
}

export default FeatureHighlight