import React from "react"

const SPMark = ({hue = '#ff5e00'}) =>  (
  <svg width="100%" height="100%" viewBox="0 150 1060 600" >
    <g>
      <path d="M361.251,543.707l90.53,-90.49l90.529,90.49l181.059,-180.98l90.53,90.49l-271.589,271.469l-181.059,-180.979Z" fill={hue} stroke={'#686868'} strokeWidth={3.5}/>
      <path d="M542.31,362.727l90.53,90.49l-90.53,90.49l-90.529,-90.49l90.529,-90.49Z" fill={'#ccc'}/>
      <path d="M632.84,272.237l90.529,90.49l-90.529,90.49l-90.53,-90.49l90.53,-90.49Z" fill={'#b3b3b3'}/>
      <path d="M542.31,181.688l90.53,90.549l-90.53,90.49l-90.509,-90.529l90.509,-90.51Z" fill={'#999'}/>
      <path d="M451.781,272.237l90.529,90.49l-90.529,90.49l-90.53,-90.49l90.53,-90.49Z" fill={'#808080'}/>
      <path d="M361.251,362.727l90.53,90.49l-90.53,90.49l-90.529,-90.49l90.529,-90.49Z" fill={'#666'}/>
      <path d="M260.82,453.296l281.49,-281.326c93.83,93.776 187.661,187.551 281.491,281.326l-281.491,281.367l-281.49,-281.367Zm462.599,-80.53l-181.109,180.86l-90.519,-90.519l-80.65,80.668l171.169,171.093l261.687,-261.571l-80.578,-80.531Zm-442.795,80.531l80.616,80.58l80.652,-80.67l-80.566,-80.565l-80.702,80.655Zm181.066,-0.091l80.575,80.576l80.661,-80.502l-80.646,-80.682l-80.59,80.608Zm90.488,-90.508l80.658,80.693l80.68,-80.522l-80.787,-80.74l-80.551,80.569Zm-180.95,0.047l80.562,80.562l80.593,-80.61l-80.512,-80.547l-80.643,80.595Zm90.545,-90.492l80.508,80.543l80.546,-80.563l-80.517,-80.47l-80.537,80.49Z" fill={'hsl(0, 0%, 30%)'}/>
    </g>
  </svg>
)

export default SPMark;