import styled from "styled-components"
import { motion } from "framer-motion"

export const Payoff = styled('h1')`
  font-weight: 100;
  text-transform: uppercase;
  text-align: center;
  padding-top: 40vh;
  font-size: 8vmin;
  color: var(--sp-gray);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  & em {
    font-weight: 400;
    font-style: normal;
  }
  & strong{
    font-weight: 900;
  }
`

export const Blurb = styled('p')`
  color: var(--sp-gray);
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 150px;
  @media(max-width: 768px){
    margin-bottom: 60px;
    max-width: 80vw;
    & br {
      display: none;
    } 
  }
`;

export const HeroImage = styled(motion.img)`
  width: calc(100% - 300px);
  min-width: 80vw;
  box-shadow: 0 16px 32px 0 rgba(179, 86, 35, 0.39);
  margin: 0 auto;
  display: block;
`

export const CTABar = styled('div')`
  width: 100%;
  height: 52px;
  margin-top: 25px;
  margin-bottom: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  & img {
    height: 52px;
    margin-right: 6px;
    margin-left: 6px;
    box-shadow: 0 12px 24px 0 #ffa875;
    border-radius: 9px;
    cursor: pointer;
  }
  @media(max-width: 768px){
    margin-bottom: 60px;
  }
`;

export const CTA = styled(motion.div)`
  background: linear-gradient(0deg,var(--sp-orange) 0%, hsl(22, 100%, 67%) 100%);
  border: 1px solid #ffbe99;
  position: relative;
  overflow: hidden;
  text-shadow: 0 2px 2px var(--sp-orange);
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  line-height: 52px;
  padding: 0 20px;
  &:before {
    content: '';
    display: block;
    width: 100px;
    height: 100px;
    overflow: hidden;
    background: hsla(0, 0%, 100%, 0.15);
    position: absolute;
    right: 12%;
    transform: rotate(45deg) translateY(-70%);
  }
`;

export const Columns = styled('div')`
  margin-top: 120px;
  display: grid;
  padding-left: clamp(36px, 15vw, 250px);
  padding-right: clamp(36px, 15vw, 250px);
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: max-content auto;
  grid-gap: 48px;
  margin-bottom: 120px;
  & h3:not(:nth-child(2)) {
   text-align: left;
  }
  & img {
    height: 100px;
    margin: 0 auto;
    display: block;
    margin-bottom: 24px;
  }
  @media(max-width: 768px){
    margin-top: 60px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-auto-flow: row;
  }
`

export const ClosingRow = styled('div')`
  grid-row: 2 / 3;
  grid-column: 1 / -1;
  text-align: center;
  padding-top: 48px;
  h3{
    width: 100%;
    text-align: center !important;
    text-transform: uppercase;
  }
  //margin-bottom: 1200px;
  & img {
    display: block;
    max-height: 100%;
    height: 240px;
    object-fit: contain;
    object-position: center;
    margin: 0 auto;
  }
  & p{
    font-size: 20px;
  }
  @media(max-width: 768px){
    grid-row: unset;
  }
`

export const Sendoff = styled('h2')`
  padding-top: 120px;
  padding-bottom: 60px;
  color: var(--sp-orange);
  & img {
    box-shadow: 0 12px 24px 0 #ffa875;
    margin-top: 36px;
    border-radius: 7px;
    cursor: pointer;
    height: 52px;
  }
`