import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import SPMark from './SPMark';

const Card = styled(motion.div)`
  font-size: 16px;
  line-height: 1.2;
  font-style: italic;
  width: 300px;
  min-height: 380px;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 12px 24px 0 rgba(138, 60, 15, 0.31);
  border: 1px solid ${props=>`hsla(${props.hue}, 0.5)`};
  position: relative;
  overflow: hidden;
  &:nth-of-type(2){
    padding-top: 24px;
    padding-bottom: 24px;
  }
  & svg {
    position: absolute;
    height: 80px;
    margin-top: 40px;
    margin-bottom: 21px;
  }
  &:nth-of-type(2){
    //min-height: 420px;
  }
  &:nth-of-type(3){
    //min-height: 460px;
  }
  @media(max-width: 768px){
    margin-bottom: 36px;
  }
`;

const Content = styled('div')`
  margin-top: 140px;
  h4 {
    font-size: 20px;
    letter-spacing: 0;
    line-height: 1.8;
    font-weight: 700;
    text-align: center;
    font-style: normal;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  h5 {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 1.2;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    margin-top: 24px;
  }
  ul {
    margin-top: 24px;
    list-style-type: none;    
    font-size: 12px;
    line-height: 1.6;
    font-weight: 400;
    font-style: normal;
    padding: 42px;
    padding-bottom: 24px;
    padding-top: 0;
  }
`

const Diagonal = styled('div')`
  width: 600px;
  height: 600px;
  position: absolute;
  top: -155px;
  left: 0;
  z-index: 0;
  background: ${props=>`hsl(${props.hue})`};
  transform: translate(-50%, -50%) rotate(45deg);
  opacity: 0.3;
`

const Price = styled('div')`
  background-color: ${props=>`hsl(${props.hue})`};
  border-radius: 24px;
  padding: 6px 24px;  
  color: #ffffff;
  font-size: 16px;
  line-height: 1.8;
  font-weight: 700;
  text-align: center;
  font-style: normal;
  margin: 0 auto;
  width: min-content;
  z-index: 1;
`

const EditionCard = ({info}) => {
  const { label, hue, price, featureText, featureList } = info;
  const item = {
    hidden: { y: 100, opacity: 0},
    show: { y: 0, opacity: 1 }
  }
  return (
    <Card variants={item} hue={hue} whileHover={{scale:1.1, zIndex:3}}>
      <Diagonal hue={hue} />
      <SPMark hue={`hsl(${hue})`}/>
      <Content>
        <h4>{label}</h4>
        <Price hue={hue}>{price}</Price>
        <h5>{featureText}</h5>
        <ul>
          {featureList.map((f,i) => (<li key={`${label}_feature_${i}`}>&bull; {f}</li>))}
        </ul>
      </Content>
    </Card>
    )
}

export default EditionCard