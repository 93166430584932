import React from "react"
import styled from "styled-components"

const Container = styled('div')`
  padding-left: clamp(36px, 15vw, 250px);
  padding-right: clamp(36px, 15vw, 250px);
  padding-top: 70px;
  padding-bottom: 70px;
  margin-top: 120px;
  background: linear-gradient(286deg, #ff5e00 0%, hsl(22, 100%, 71%) 100%);
  & > h2 {
    color: #fff;
    margin-bottom: 64px;
  }
`;

const Columns = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media(max-width: 768px){
    flex-direction: column;
  }
`;

const Column = styled('div')`
  width: 30%;
  font-size: 16px;
  line-height: 1.2;
  font-style: italic;
  p{
   font-weight: 400;
   color: #ffffff;
  }
  header {
    margin-bottom: 12px;
    color: #ffffff;
    font-weight: 900;
  }
  @media(max-width: 768px){
    width: 100%;
    border-bottom: 1px solid white;
    padding-top: 24px;
    padding-bottom: 24px;
  }
`;

const Divider = styled('div')`
width: 1px;
background: #fff;
`

const Testimonials = () => (
  <Container>
    <h2>What our users are saying:</h2>
    <Columns>

      <Column>
        <blockquote>
          <header>Excellent app</header>
          <p>Mac is such a powerful platform it deserves powerful software. This app is one of the hidden gems of the Mac app store. A must-have for anyone tackling major projects.</p>
        </blockquote>
      </Column>

      <Divider />

      <Column>
        <blockquote>
          <header>Very useful, accessible and flexible</header>
          <p>I like SheetPlanner a lot: it's flexible, with just the amount of granularity I need, and intuitive to use. I've found the interface, as well as finding how-to answers, very accessible. One's work and flow takes precedence, rather than the program.</p>
        </blockquote>
      </Column>

      <Divider />

      <Column>
        <blockquote>
          <header>Extremely powerful, yet easy to use</header>
          <p>It's not every day you stumble upon a program that has this much power yet at the same time, keeps the basics extremely simple to use.</p>
        </blockquote>
      </Column>

    </Columns>
  </Container>
)

export default Testimonials