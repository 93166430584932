import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Blurb, ClosingRow, Columns, CTABar, HeroImage, Payoff, Sendoff } from "../components/styled-components"
import { motion, useTransform, useViewportScroll } from "framer-motion"
import FeatureHighlight from "../components/FeatureHighlight"
import Editions from "../components/Editions"
import Testimonials from "../components/Testimonials"
import MASBadge from "../images/mas.svg"
import SPHero from "../images/Main@2x.png"
// import SPMark from "../images/SheetPlanner_mark.svg"
import SPOutline from "../images/Get Organized@2x.png"
import SPTimeline from "../images/Make a Plan@2x.png"
import SPCalendar from "../images/Get Things Done@2x.png"
import SPFull from "../images/All the Tools for the Pros@2x.png"

import GetOrganized from '../images/getorganized.svg'
import MakeAPlan from '../images/makeaplan.svg'
import GetItDone from '../images/getitdone.svg'

const IndexPage = () => {
  const { scrollY } = useViewportScroll()
  const y = useTransform(scrollY, [0, 250], ["0%", "-20vh"]);

  return (
    <Layout>
      <SEO title="SheetPlanner - Home"/>

      {/*-------------------------------------------------------------------*/}

      <Payoff>Organize. <em>Plan.</em> <strong>Do.</strong></Payoff>
      <Blurb>
        SheetPlanner helps you organize information, manage your projects, <br />
        and get things done with unparalleled power and flexibility.
      </Blurb>
      <CTABar>
        {/*<CTA whileHover={{ scale: 1.1 }}>Free community edition</CTA>*/}
        <a href={"https://apps.apple.com/app/sheetplanner/id1438761088"} title={"SheetPlanner on the Mac App Store"}>
          <motion.img alt="SheetPlanner on Mac App Store" whileHover={{ scale: 1.1 }} src={MASBadge}/>
        </a>
      </CTABar>
      <HeroImage alt={"SheetPlanner screenshot"} style={{ y }} src={SPHero}/>

      {/*-------------------------------------------------------------------*/}

      <h2>Insights with ease and power.</h2>
      <p className={'centred'}>
        SheetPlanner allows anyone to easily organize tasks, perform long range planning, manage projects, and more.
        Users can plan and visualize goals in a convenient timeline view - by days, weeks, months, quarters, and years
        while also focusing on their most immediate tasks in the calendar. SheetPlanner makes it simple to plan and
        visualize your long term goals, helping to keep your projects and tasks on schedule until they're finished.
      </p>

      {/*-------------------------------------------------------------------*/}


      <Columns>
        <div>
          <img src={GetOrganized} alt={"Get organized..."} />
          <h3>Get organized</h3>
          <p>
            Organize all your tasks with the outliner, categorize them using the standard columns including start & finish dates.
            Create your own custom columns and link to external files and websites.
          </p>
        </div>
        <div>
          <img src={MakeAPlan} alt={"... make a plan..."} />
          <h3>Make a plan</h3>
          <p>
            Plan and visualize your goals, project tasks and schedule with the timeline view. View task duration and
            sequence. View timeline by days, weeks, months, quarters or years.
          </p>
        </div>
        <div>
          <img src={GetItDone} alt={"... and get it done."} />
          <h3>Get things done</h3>
          <p>
            Use filters to focus on what's important, due today, due tomorrow, or whatever criteria you select.
            View filtered tasks in the outline, timeline, month and yearly calendar.
          </p>
        </div>
        <ClosingRow>
          {/*<img src={SPMark} alt={'SheetPlanner logo mark'}/>*/}
          <h2>Getting things done has never been easier.</h2>
          <p>
            Let's see what SheetPlanner can do:
          </p>
        </ClosingRow>
      </Columns>


      {/*-------------------------------------------------------------------*/}
      <div style={{maxWidth: '100%', overflowX: 'hidden'}}>
      <FeatureHighlight id={'features'} imgSrc={SPOutline}>
        <h2>Get organized</h2>
        <p>
          Organize information using the Outliner to provide structure.
          Populate the start, finish and progress columns to create a schedule and track progress.
          Add custom, text, number, date, symbol, list and picture columns to track additional information.
        </p>
        <ol>
          <li>Use the <strong>Outliner</strong> to give structure to your information.</li>
          <li>Add your own <strong>Custom Columns</strong>.</li>
          <li>Customize <strong>Styles</strong> by level.</li>
          <li><strong>Link</strong> to files and websites.</li>
          <li>Add <strong>notes</strong> to rows.</li>
        </ol>
      </FeatureHighlight>


      <FeatureHighlight imgSrc={SPTimeline} ornamentLocation={'top'} flipped>
        <h2>Make a plan</h2>
        <p>
          Visualize rows with start and finish dates on the Timeline.
          Change the Timeline scope to get a high level view of your project over years or zoom in to weeks or even days for more precision.
          See the progress of each task in the Timeline bars.
          Customize the color of Timeline bars.
          Preview and print the Timeline.
        </p>
        <ol>
          <li>Track deadlines using the <strong>Timeline</strong>.</li>
          <li>Change the <strong>Timeline</strong> scope to see the big picture.</li>
          <li>Customize the <strong>Timeline</strong>.</li>
          <li>Add <strong>Dependencies</strong> to create task relationships.</li>
        </ol>
      </FeatureHighlight>


      <FeatureHighlight imgSrc={SPCalendar} ornamentLocation={'left'} >
        <h2>Get things done</h2>
        <p>
          Use filters to focus on what's important, due today, due tomorrow, or whatever criteria you select.
          View filtered tasks in the outline, timeline, month and yearly calendar.
          Check items off as they are completed in the Outline, Timeline, Month or Year views.
          Hide completed items to stay focused.
        </p>
        <ol>
          <li><strong>Filter</strong> rows to view only what you need.</li>
          <li><strong>Focus</strong> on a particular outline section.</li>
          <li>View dates on the <strong>Calendar</strong>.</li>
          <li>Show/hide <strong>Completed</strong>.</li>
        </ol>
      </FeatureHighlight>

      <FeatureHighlight imgSrc={SPFull} ornamentLocation={'left'} flipped>
        <h2>All the tools for the pros</h2>
        <p>
          SheetPlanner 2.0 includes many features that more advanced users will enjoy.
        </p>
        <ol>
          <li>Create multiple <strong>Sheets</strong>.</li>
          <li>Organize sheets into <strong>Folders</strong>.</li>
          <li>Use <strong>Tabs</strong> to view multiple sheets.</li>
        </ol>
      </FeatureHighlight>
      </div>
      {/*-------------------------------------------------------------------*/}

      <Editions id={'pricing'}/>

      {/*-------------------------------------------------------------------*/}

      <Testimonials/>

      {/*-------------------------------------------------------------------*/}

      <Sendoff>
        Get your business on track,<br/>get SheetPlanner today.<br/>
        <a href={"https://apps.apple.com/app/sheetplanner/id1438761088"} title={"SheetPlanner on the Mac App Store"}><motion.img whileHover={{scale: 1.1}} src={MASBadge} alt={"SheetPlanner on the Mac App Store"}/></a>
      </Sendoff>


      {/*<Link to="/page-2/">Go to page 2</Link> <br />*/}
      {/*<Link to="/using-typescript/">Go to "Using TypeScript"</Link>*/}
    </Layout>
  )
}

export default IndexPage
